<template>
  <div
    class="row"
    style="padding: 10px"
    :style="{ background: `linear-gradient(${this.color}, black)`}"
  >

    <h4 class="section-header text-left">
      <img
        v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
        :alt="incomingParty.title"
        height="40px"
        width="40px"
        style="border-radius: 300px"
      />
      Next Event </h4>
    <p style="margin-left: 55px;font-size: 0.7rem;margin-top: -35px;text-transform: uppercase">
      {{ this.nextEventDate }}</p>

    <div class="centered card">
      <h3 v-if="incomingParty.title">{{ incomingParty.title.split("|")[0] }}</h3>
      <EventCard
        :withLineup="false"
        :with-title="false"
        v-bind:event="incomingParty"
      >
      </EventCard>
    </div>
  </div>

</template>
<script>
import MiniEventCard from '@/components/EventCard/MiniEventCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"
import EventCard from "@/components/EventCard/EventCard";
import moment from "moment";

export default {
  name: 'incomingParty',
  components: {MiniEventCard, RsvpViaDialog, EventCard},
  computed: {
    nextEventDate: {
      get() {
        moment()
        return this.incomingParty && moment(this.incomingParty.metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    },
  },
  props: {
    logo: {},
    color: {},
    incomingParty: {}
  }
}
</script>
